@import './reset.css';
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');


:root {
  --bg-color: #110814;
  --font-color: #bfc88d;
  --font-color-light: #bfc88d;
  --border-color: #bfc88d;
}

[data-theme="light"] {
  --bg-color: #bfc88d;
  --font-color: #110814;
  --font-color-light: #110814;
  --border-color: #110814;
}


* {
  box-sizing: border-box;
}

html {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
}

.small {
  font-size: 14px;
  color: var(--font-color-light);
}

/* Main Layout */

.main {
  background-color: var(--bg-color);
  color: var(--font-color);
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 24px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 24px;
  padding-left: 24px;
}

.nav h1 {
  font-size: 32px;
}

.content {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 700px) {
  .content {
    padding: 0 24px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
}

/* Components */

.loader {
  height: 22px;
  width: 22px;
  background-color: var(--font-color);
  animation: rotate 1500ms infinite;
}

/* <AccountInfo /> */



.account-info__wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.account-info p:first-of-type {
  margin-bottom: 4px;
}

.account-info__wrapper img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.account-row {
  display: flex;
  align-items: center;
  gap: 24px;
}

/* <Module /> */

.module,
.module--full-width {
  border-top: 2px solid var(--border-color);
  width: 100%;
}

.module__title {
  border-bottom: 2px solid var(--border-color);
  font-size: 22px;
  padding: 24px;
  font-weight: bold;
}

.module__children {
  padding: 24px;
}

@media screen and (min-width: 700px) {
  .module,
  .module--full-width {
    width: 100%;
  }

  .module {
    border: 2px solid var(--border-color);
    width: calc(50% - 12px);
  }

  .module--full-width {
    width: 100%;
  }
}


/* <ResumeModule /> */

.resume-module__list-item {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 32px;
  font-weight: 700;
}

.resume-module__list-item > img {
  width: 50px;
}

.resume-module__list-item__content > * + * {
  margin-top: 6px;
}

.resume-module__list-item__content p {
  font-size: 16px;
}

/* <StatsModule /> */

.stats-module {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  font-size: 18px;
  font-weight: 700;
}

.stats-module__list-item > * + * {
  margin-top: 10px;
}

.stats-module__list-item .label {
  font-size: 12px;
}

/* <WeatherModule /> */

.weatherdata {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  gap: 24px;
}

.weatherdata h1 {
  font-size: 64px;
  font-weight: bold;
}

.weatherdata__subline {
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

/* tracks */

.tracklist {
  display: grid;
  flex-wrap: wrap;
  gap: 12px;
  grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
}


.track {
  display: flex;
  align-items: center;
  gap: 8px;
  /* border: 1px solid var(--border-color); */
}

.track img {
  height: 64px;
}

.trackname {
  font-weight: bold;
}

.trackinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  padding: 10px;
}

/* ThemeButton */

.theme-button-row {
  display: flex;
  align-items: center;

}

.theme-button {
  cursor: pointer;
  display: inline-block;
  border: none;
  background-color: transparent;
  color: inherit;
  margin: 0;
  text-decoration: none;
  line-height: 1;
  font-size: 20px;
  width: 48px;
  height: 24px;
  border: 2px solid var(--font-color);
  position: relative;
}

.light-switch {
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  background-color: var(--font-color);
}



/* Keyframe animations */

@keyframes rotate {
  from {
    transform: rotate(0deg) scale(1.1);
  }

  to {
    transform: rotate(359deg) scale(1);
  }
}